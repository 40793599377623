import { configureStore } from '@reduxjs/toolkit';
import productUploadReducer from '../features/productUploadSlice';
import onneMappingFilterReducer from '../features/onneMappingFilterSlice';
import dataModelReducer from '../features/dataModelSlice';
import sourceItemReducer from '../features/sourceItemSlice';
import selectedDataModelReducer from '../features/selectedDataModelSlice';
import gpcReducer from '../features/gpcSlice';
import optionsReducer from '../features/optionsSlice';
import targetModelReducer from '../features/targetModelSlice';
import refreshDataModelsReducer from '../features/refreshDataModelsSlice';
import dmFieldsDataReducer  from '../features/dmFieldsDataSlice';
import openAlertReducer from '../features/openAlertSlice';
import selectedIndexReducer from '../features/selectedIndexSlice';

export default configureStore({
  reducer: {
    productUpload: productUploadReducer,
    onneMappingFilter: onneMappingFilterReducer,
    dataModel: dataModelReducer,
    selectedDataModel: selectedDataModelReducer,
    gpc: gpcReducer,
    options: optionsReducer,
    targetModel: targetModelReducer,
    sourceItem: sourceItemReducer,
    refreshDataModels: refreshDataModelsReducer,
    dmFieldsData: dmFieldsDataReducer,
    openAlert: openAlertReducer,
    selectedIndex: selectedIndexReducer,
  },
})