import React, { useState, useEffect } from "react";
import { useNavigate  } from 'react-router-dom';
import {  Box, Typography } from '@mui/material';
import useToolsProductUpload from "../../tools/toolsProductUpload";
import useToolsNotifications from "../../tools/ToolsNotifications";
import ProductTable from "../ProductTable/ProductTable";
import { useSelector } from 'react-redux';
import onneMappingFilterSlice from "../../features/onneMappingFilterSlice";

export default function WorkspaceViewItems({sourceItems, mapping, loading, handleOnRowClick, setSelectionModel}) {
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const {error, success} = useToolsNotifications();
    const {updateProduct} = useToolsProductUpload();
    const navigate = useNavigate();
    const [header, setHeader] = useState([]);
    const [processedItems, setProcessedItems] = useState([]);


    useEffect(() => {
        if(sourceItems !== undefined) {
            processHeader(sourceItems);
            processItems(sourceItems);
        }
    }, [sourceItems])

    const processItems = (items) => {
        let processed = [];

        items.map((elem) => {
            elem.item.id = elem.id;
            processed.push(elem.item)
        })

        setProcessedItems(processed);
    }

    const processHeader = (headerData) => {
        let colNumber = 6;
        let colCount = 0;
        const keyTargetFieldId = selectedDataModel.key;
        if(mapping[keyTargetFieldId] === undefined) {
            alert('To use the Product Browser you first need to map "' + keyTargetFieldId + '" field, as is the key field for this data model');
            navigate('/mapping')
            return;
        }
        const keySrcFieldId = mapping[keyTargetFieldId].value;

        let processedHeader = [];

        let preprocessedHeader = [];
        for(const key in headerData) {
            preprocessedHeader = [...new Set([...preprocessedHeader,...Object.keys(headerData[key].item)])]
        }

        let targetMapping = undefined;
        for(const targetFieldId in mapping) {
            if(mapping[targetFieldId].value ===  keySrcFieldId) {
                targetMapping = targetFieldId;
                break;
            }
        }

        processedHeader.unshift(
        {
            field: keySrcFieldId,
            headerName: keySrcFieldId,
            minWidth: 160,
            align: 'left',
            editable: false, // TODO
            renderHeader: () => (
                <Typography variant="tableHeaderLight">{keySrcFieldId} {targetMapping !== undefined ? "(" + targetMapping + ")" : ""}</Typography>
            )
        })
        colCount++;
        
        for(const key in preprocessedHeader) {
            if (colCount === colNumber) { break; }
            if(keySrcFieldId !== preprocessedHeader[key]) {
                let targetMapping = undefined;
                for(const targetFieldId in mapping) {
                    if(mapping[targetFieldId].value ===  preprocessedHeader[key]) {
                        targetMapping = targetFieldId;
                        break;
                    } else {
                        if(mapping[targetFieldId].uom ===  preprocessedHeader[key]) {
                            targetMapping = targetFieldId + "_uom";
                            break;
                        }
                    }
                }

                processedHeader.push(
                {
                    field: preprocessedHeader[key],
                    headerName: preprocessedHeader[key],
                    minWidth: 160,
                    align: 'left',
                    editable: true, // TODO
                    renderHeader: () => (
                        <Typography variant="tableHeaderLight">{preprocessedHeader[key]} {targetMapping !== undefined ? "(" + targetMapping + ")" : ""}</Typography>
                    )
                })

                colCount++;
            }
        }
        setHeader(processedHeader);
    }



    let mainStyle = { marginTop: "20px" };
    let prodsStyle = { paddingLeft: "20px", paddingRight: "20px" };

    return  <Box name="Mapping" height="100%" width="100%">
                <ProductTable 
                    hideSelectAll={true}
                    loading={loading} 
                    handleOnRowClick={handleOnRowClick} 
                    setSelectionModel={setSelectionModel} 
                    rows={processedItems} 
                    header={header} 
                    rowCount={processedItems.length} 
                />
            </Box>;
}
